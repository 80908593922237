import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts'

import SEO from '../components/seo'
import getPageLinks from '../utils/getPageLinks'
import PageSwitch from '../components/pageSwitch'
import { TransitionPortal } from 'gatsby-plugin-transition-link'

import workArrow from '../assets/images/projects-arrow.svg'
import downArrow from '../assets/images/down-arrow.svg'

import Fade from 'react-reveal/Fade'

const Content = (data) => {
  return (
    <>
      <section className="work__header">
        <Link to="/">
          <img src={workArrow} alt="Cam Richards - Back Home" />
        </Link>
        <a className="git" href="mailto:">
          Get In Touch
        </a>
      </section>
      <section className="work__wrapper">
        <h1>Work</h1>
        <p>
          A collection of work covering <br />
          Product, UX and Digital Design.
        </p>
        <img
          className="work__down"
          src={downArrow}
          alt="Cam Richards - Work"
        />
        <div className="work__items">
          { data.projects.edges.map((el, i) => {
            return (
              <div className="work__item" key={i}>
                <Fade bottom distance="30px">
                  <Link to={`/${el.node.slug}`}>
                    { el.node.acf.featured_image.localFile.childImageSharp.original.src && <img src={el.node.acf.featured_image.localFile.childImageSharp.original.src} alt={el.node.title} /> }
                    <h4>{el.node.title}</h4>
                    { el.node.acf.excerpt && <div dangerouslySetInnerHTML={{ __html: el.node.acf.excerpt }} /> }
                    <span className='work__roles'>
                    { el.node.acf.primary_roles && el.node.acf.primary_roles.map((item, x) => {
                      return (
                        <span key={x}>
                          { item.text }
                        </span>
                      )
                    }) }
                    </span>
                  </Link>
                </Fade>
              </div>
            )
          }) }
        </div>
      </section>
    </>
  )
}

class WorkPage extends Component {
  render() {
    const { location } = this.props
    const { projects } = this.props.data
    return (
      <>
        <SEO
          title="Cam Richards"
          description="User Experience & Product Designer"
          bodyClass="work"
        />
        <div className="main-wrap main-wrap-work">
          <div className="main page-main-work">
            <div><Content projects={projects} /></div>
          </div>
          <PageSwitch location={location} />
        </div>
      </>
    )
  }
}

export const workQuery = graphql`
  query {
    projects: allWordpressWpCaseStudy(sort: {fields: [menu_order], order: ASC}) {
      edges {
        node {
          title
          slug
          acf {
            excerpt
            featured_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            primary_roles {
              text
            }
          }
        }
      }
    }
  }
`

export default WorkPage
